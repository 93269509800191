"use client";

import {
  useFinancialPlansStatusQueryOptions,
  useQuerySuccessMeter,
} from "#app/_api/financial-plans-service";
import { Card, CardContent, CardFooter } from "#ui/card";
import { LinkButton } from "#ui/link";
import { useQuery } from "@tanstack/react-query";
import ProbabilityOfSuccessMeeter from "./ProbabilityOfSuccessMeter";
import { PendingCard } from "../../cards/PendingCard";
import { ErrorCard } from "../../cards/ErrorCard";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogMain,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "#ui/alert-dialog";
import { Alert, AlertContent } from "#ui/alert";
import { Button } from "#ui/button";
import { Icon } from "#ui/icon";
import { H6, P } from "#ui/typography";
import Image from "next/image";

function Disclaimer() {
  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <Icon
          name="ms-info"
          aria-label="More info"
          size="sm"
          palette="accent"
          inline
        />
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent variant="center-scroll-inner">
          <AlertDialogHeader>
            <AlertDialogTitle>Plan Probability of Success</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogMain>
            <div className="space-y-15px">
              <Alert intent="warning">
                <AlertContent>
                  <b>Disclaimer:</b> The plan probability of success may not
                  reflect your current financial situation. Certain inputs to
                  the calculation are only updated when the report is ran or 360
                  Wealth account errors are addressed. If you have any
                  questions, please reach out to your advisory team.
                </AlertContent>
              </Alert>
            </div>
          </AlertDialogMain>
          <AlertDialogFooter>
            <AlertDialogCancel asChild>
              <Button variant="outline">Close</Button>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

function MeterCard() {
  const { isPending, isError } = useQuerySuccessMeter();
  if (isError) {
    return <ErrorCard />;
  }

  if (isPending) {
    return <PendingCard />;
  }

  return (
    <Card>
      <CardContent className="text-center">
        Plan Probability of Success <Disclaimer />
        <ProbabilityOfSuccessMeeter />
      </CardContent>
      <CardFooter palette="white">
        <LinkButton className="w-full" href="/planning/my-plan">
          See my plan
        </LinkButton>
      </CardFooter>
    </Card>
  );
}

function MarketingCard() {
  return (
    <Card className="overflow-hidden">
      <CardContent style={{ padding: "0" }}>
        <div className="bg-secondary-10 pl-10px">
          <div style={{ padding: "19px 0 0 74px", position: "absolute" }}>
            <Image
              src="/Images/marketing.png"
              alt="Computer showing background"
              width="236"
              height="140"
              priority
            />
          </div>
          <Image
            src="/Images/marketing-background.png"
            alt="Computer showing background"
            width="410"
            height="180"
            priority
          />
        </div>
        <div className="px-30px py-20px">
          <H6 className="text-wrap pb-15px">
            Planning for your future. Let your advisor know if your finances
            have changed.
          </H6>
          <P size="sm" className="pr-10px text-shade-70">
            Your Baird Financial Advisor can help you prioritize and plan so you
            can achieve your goals of tomorrow.
          </P>
        </div>
      </CardContent>
      <CardFooter palette="white">
        <LinkButton className="w-full" href="/planning">
          Get Started
        </LinkButton>
      </CardFooter>
    </Card>
  );
}

export function SuccessMeter() {
  const { data, isPending, isError } = useQuery(
    useFinancialPlansStatusQueryOptions(),
  );

  if (isPending) {
    return <PendingCard />;
  }

  if (isError) {
    return <ErrorCard />;
  }

  if (data.canUseFinancialPlanning && data.hasVisiblePlans) {
    return <MeterCard />;
  }

  return <MarketingCard />;
}
