import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
import { useQuerySuccessMeter } from "#app/_api/financial-plans-service";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
  HighchartsMore(Highcharts);
  SolidGauge(Highcharts);
}

const TextColor1 = "#515151";
const getOptions = (targetLow = 0, targetHigh = 0, value = 0) => {
  return {
    credits: {enabled: false},
    exporting: false,
    title: null,
    chart: {
      backgroundColor: null,
      width: 240,
      height: 180,
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      type: "gauge",
    },
    pane: {
      background: [
        {
          backgroundColor: null,
          borderWidth: 0,
        },
      ],
      size: "130%",
      center: ["50%", "66%"],
      startAngle: -120,
      endAngle: 120,
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPositions: [0, targetLow, targetHigh], // set number labels for Confidence Zone range
      tickPosition: "inside",
      lineColor: "transparent",
      tickLength: 0,
      minorTickWidth: 0,
      labels: {
        enabled: false,
        distance: 0,
        format: "{value}",
      },
      plotBands: [
        {
          className: `meter-fill-below`,
          color: "#924D8C",
          from: 1, //  Below Confidence Range
          innerRadius: "70%",
          to: targetLow,
        },
        {
          className: `meter-fill-within`,
          color: "#669933",
          from: targetLow,
          innerRadius: "70%",
          to: targetHigh,
        },
        {
          className: `meter-fill-above`,
          color: "#63c5d5",
          from: targetHigh,
          innerRadius: "70%",
          to: 100,
        },
      ],
    },
    plotOptions: {
      gauge: {
        dataLabels: {
          className: "c-monte-carlo-success-meter__label",
          borderRadius: 0,
          borderWidth: 0,
          verticalAlign: "bottom",
          color: "#555555",
          useHTML: true,

          style: { fontSize: "1.7em", paddingTop: "40px" },

          format:
            '<div style="top: -28px; left: -20px; position: absolute">{y}<span class="c-monte-carlo-success-meter__label-unit">%</span></div>',
        },
        dial: {
          radius: "55%",
          backgroundColor: TextColor1,
          baseWidth: 90,
          baseLength: "0%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: "#FFFFFF",
          radius: 50,
          borderColor: "rgba(0,0,0,0.05)",
          borderWidth: 4,
        },
        wrap: false,
      },
    },
    series: [
      {
        animation: {
          duration: 1500,
          easing: "easingFunction",
        },
        data: [value],
        enableMouseTracking: false,
        dataLabels: {
          useHTML: true,
        },
        type: "gauge",
      },
    ],
  };
};

export default function ProbabilityOfSuccessMeeter() {
  const { data } = useQuerySuccessMeter();
  const { targetLow, targetHigh, probabilityOfSuccess } = data || {};
  const chartOptions = getOptions(targetLow, targetHigh, probabilityOfSuccess);
  return (
    <div className="width-full flex flex-row justify-center">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
}
