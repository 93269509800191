import { getJson } from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { queryOptions } from "@tanstack/react-query";

export type Portfolio = {
  accounts: Account[];
  totals: Totals;
};

export type Totals = {
    value: number,
    change: number,
    percentChange: number,
    balances: Balances
};

export type Balances = {
  cashValue: number
};

export type Account = {
  number: string;
  value: number;
  nickname: string;
  accountType: string;
};

const GATEWAY_PATH = "pwmportfolios/v1";

export async function getPortfolio(): Promise<Portfolio> {
  const result = await getJson<Portfolio>(`/${GATEWAY_PATH}/Portfolio`);
  return result;
}

export const portfolioQueryKeys = createQueryKeys("Portfolio", {
  portfolio: {
    queryKey: null,
    queryFn: getPortfolio,
  },
});

export function useQueryOptionsPortfolio() {
  return queryOptions(portfolioQueryKeys.portfolio);
}

export function usePortfolioQueryOptions() {
  const defaultData: Portfolio | undefined = undefined;
  return queryOptions({
    queryKey: portfolioQueryKeys.portfolio.queryKey,
    queryFn: (context) =>
      (
        portfolioQueryKeys.portfolio.queryFn(context) as Promise<Portfolio>
      ).catch((_) => defaultData),
    staleTime: 0,
  });
}
