
export interface MoneyProps {
    value:number|null|undefined;
}


export function Money({value}: MoneyProps) {    

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    
      if (value==null || value == undefined)
        return "";

    return (formatter.format(value));
}